import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const DropboxCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState('Processing...');

  useEffect(() => {
    document.title = "Connecting to Dropbox";
    
    const processOAuthCode = async () => {
      try {
        // Get the code from URL query parameters
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        
        if (!code) {
          navigate('/dropbox-error?message=No authorization code received');
          return;
        }
        
        // Forward to your Cloud Function
        window.location.href = `https://handledropboxoauth-bdqs4ynyca-uc.a.run.app?code=${code}`;
      } catch (err) {
        console.error('Error in Dropbox callback:', err);
        navigate('/dropbox-error?message=' + encodeURIComponent(err.message));
      }
    };
    
    processOAuthCode();
  }, [navigate, location]);

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h2>Connecting to Dropbox</h2>
      <p>{status}</p>
      <div className="loading-spinner"></div>
    </div>
  );
};

export default DropboxCallback;