import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Container,
  Modal,
  Card,
  CardContent,
  FormControl,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  IconButton,
  Checkbox,
  CssBaseline,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  AccessTime as AccessTimeIcon,
  Comment as CommentIcon,
  PlayArrow,
  Pause,
  VolumeUp,
  VolumeOff,
  Fullscreen,
  CheckCircle,
  Delete as DeleteIcon,
} from "@mui/icons-material";

const VideoReview = () => {
  const { reviewID } = useParams();
  const [review, setReview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [commenterName, setCommenterName] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [commentColor, setCommentColor] = useState("#2196f3");
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [duration, setDuration] = useState(0);
  const [videoTime, setVideoTime] = useState(0);
  const [selectedVersionIndex, setSelectedVersionIndex] = useState(null);
  const [showControls, setShowControls] = useState(true);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const seekBarRef = useRef(null);
  const seekBarContainerRef = useRef(null);
  const controlsTimeoutRef = useRef(null);
  const initialLoadRef = useRef(true);
  const db = getFirestore();

  const theme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#121212",
        paper: "#1e1e1e",
      },
    },
  });

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    color: "white",
  };

  useEffect(() => {
    const savedName = localStorage.getItem("commenterName");
    if (savedName) {
      setCommenterName(savedName);
    }

    const savedPassword = localStorage.getItem(`${reviewID}_authKey`);
    if (savedPassword) {
      setPassword(savedPassword);
    }
  }, [reviewID]);

  useEffect(() => {
    const fetchReview = async () => {
      try {
        const reviewRef = doc(db, "video_reviews", reviewID);
        const reviewDoc = await getDoc(reviewRef);

        if (reviewDoc.exists()) {
          const savedAuthKey = localStorage.getItem(`${reviewID}_authKey`);
          if (
            savedAuthKey &&
            (!reviewDoc.data().password ||
              savedAuthKey === reviewDoc.data().password)
          ) {
            setAuthenticated(true);
          }

          return onSnapshot(reviewRef, (doc) => {
            if (doc.exists()) {
              const reviewData = { id: doc.id, ...doc.data() };
              setReview(reviewData);
              
              // Only set the selected version index on initial load
              if (initialLoadRef.current && reviewData.versions && reviewData.versions.length > 0) {
                setSelectedVersionIndex(reviewData.versions.length - 1);
                initialLoadRef.current = false;
              }
            } else {
              setError("Review not found");
            }
            setLoading(false);
          });
        } else {
          setError("Review not found");
          setLoading(false);
        }
      } catch (err) {
        setError(`Error fetching review: ${err.message}`);
        setLoading(false);
      }
    };

    fetchReview();
  }, [reviewID, db]);

  // Controls visibility management
  useEffect(() => {
    const resetControlsTimeout = () => {
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }

      setShowControls(true);

      if (isPlaying) {
        controlsTimeoutRef.current = setTimeout(() => {
          setShowControls(false);
        }, 1200);
      }
    };

    resetControlsTimeout();

    return () => {
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }
    };
  }, [isPlaying]);

  const handleMouseMove = () => {
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }

    setShowControls(true);

    if (isPlaying) {
      controlsTimeoutRef.current = setTimeout(() => {
        setShowControls(false);
      }, 1200);
    }
  };

  const handleVersionChange = (event) => {
    const index = parseInt(event.target.value);
    setSelectedVersionIndex(index);
    // Reset video state when changing versions
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.controls = false;
      setIsPlaying(false);
      setVideoTime(0);
      if (seekBarRef.current) {
        seekBarRef.current.value = 0;
      }
    }
  };

  const handleAuthenticate = () => {
    if (!review) return;

    if (!review.password || password === review.password) {
      setAuthenticated(true);
      localStorage.setItem("commenterName", commenterName);
      localStorage.setItem(`${reviewID}_authKey`, password);
    } else {
      setError("Incorrect password");
    }
  };

  const handleAddComment = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.controls = false;
      setCurrentTime(videoRef.current.currentTime);
      setCommentModalOpen(true);
    }
  };

  const formatTime = (timeInSeconds) => {
    if (!timeInSeconds) return "00:00";
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSaveComment = async () => {
    if (!newComment.trim()) return;

    try {
      const latestVersionIndex = review.versions.length - 1;
      const comment = {
        comment: newComment,
        colour: commentColor,
        time: currentTime,
        timeFormatted: formatTime(currentTime),
        commenter: commenterName,
        timestamp: new Date().toISOString(),
        completed: false,
      };

      const updatedVersions = [...review.versions];

      if (!updatedVersions[latestVersionIndex].comments) {
        updatedVersions[latestVersionIndex].comments = [];
      }

      updatedVersions[latestVersionIndex].comments.push(comment);

      const reviewRef = doc(db, "video_reviews", reviewID);
      await updateDoc(reviewRef, { versions: updatedVersions });

      setCommentModalOpen(false);

      setNewComment("");
      videoRef.current.play();
      videoRef.current.controls = false;
    } catch (err) {
      setError(`Error saving comment: ${err.message}`);
    }
  };

  const handleToggleCompleted = async (commentIndex, currentCompleted) => {
    try {
      // Store the current selected version index
      const currentVersionIndex = selectedVersionIndex;
      
      const updatedVersions = [...review.versions];

      updatedVersions[currentVersionIndex].comments[commentIndex].completed =
        !currentCompleted;

      const reviewRef = doc(db, "video_reviews", reviewID);
      await updateDoc(reviewRef, { versions: updatedVersions });
      
      // No need to manually update state here as the onSnapshot listener
      // will update the review data
    } catch (err) {
      setError(`Error updating comment: ${err.message}`);
    }
  };

  const handleDeleteComment = async () => {
    if (commentToDelete === null) return;

    try {
      const updatedVersions = [...review.versions];

      // Remove the comment at the specified index
      updatedVersions[selectedVersionIndex].comments.splice(commentToDelete, 1);

      const reviewRef = doc(db, "video_reviews", reviewID);
      await updateDoc(reviewRef, { versions: updatedVersions });

      setDeleteConfirmOpen(false);
      setCommentToDelete(null);
    } catch (err) {
      setError(`Error deleting comment: ${err.message}`);
    }
  };

  const handleOpenDeleteConfirm = (index, e) => {
    e.stopPropagation();
    setCommentToDelete(index);
    setDeleteConfirmOpen(true);
  };

  const handleSeekToComment = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time;
      videoRef.current
        .play()
        .catch((err) => console.error("Error playing video:", err));
      setIsPlaying(true);
    }
  };

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        videoRef.current.controls = false;
      } else {
        videoRef.current
          .play()
          .catch((err) => console.error("Error playing video:", err));
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  const toggleFullscreen = () => {
    // For iOS compatibility, we need to use the webkitEnterFullscreen method on the video element
    if (videoRef.current) {
      if (videoRef.current.webkitEnterFullscreen) {
        // iOS Safari
        videoRef.current.webkitEnterFullscreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        // Chrome, Safari
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.requestFullscreen) {
        // Standard method
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        // IE/Edge
        videoRef.current.msRequestFullscreen();
      } else if (videoContainerRef.current) {
        // Try the container as fallback
        if (videoContainerRef.current.requestFullscreen) {
          videoContainerRef.current.requestFullscreen();
        } else if (videoContainerRef.current.webkitRequestFullscreen) {
          videoContainerRef.current.webkitRequestFullscreen();
        } else if (videoContainerRef.current.msRequestFullscreen) {
          videoContainerRef.current.msRequestFullscreen();
        }
      }
    }
  };

  const handleSeekBarChange = (e) => {
    if (videoRef.current && seekBarRef.current) {
      const time = (seekBarRef.current.value / 100) * videoRef.current.duration;
      videoRef.current.currentTime = time;
      setVideoTime(time);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setVideoTime(videoRef.current.currentTime);
      if (seekBarRef.current) {
        seekBarRef.current.value =
          (videoRef.current.currentTime / videoRef.current.duration) * 100;
      }
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
    }
  };

  const handleVideoEnded = () => {
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current
          .play()
          .catch((err) => console.error("Error playing video:", err));
        videoRef.current.controls = false;
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        videoRef.current.controls = false;
        setIsPlaying(false);
      }
    }
  };

  const renderCommentMarkers = () => {
    if (!duration || !selectedVersion?.comments?.length) return null;

    return selectedVersion.comments.map((comment, index) => {
      const position = (comment.time / duration) * 100;
      return (
        <div
          key={index}
          style={{
            position: "absolute",
            bottom: "100%",
            left: `${position}%`,
            width: "4px",
            height: "8px",
            backgroundColor: comment.colour || "#ff5722",
            transform: "translateX(-50%)",
            marginBottom: "2px",
          }}
          title={`${comment.commenter}: ${comment.comment.substring(0, 20)}${
            comment.comment.length > 20 ? "..." : ""
          }`}
        />
      );
    });
  };

  const isLatestVersion = selectedVersionIndex === review?.versions?.length - 1;

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ pt: 4 }}>
          <Typography>Loading review...</Typography>
        </Container>
      </ThemeProvider>
    );
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ pt: 4 }}>
          <Typography color="error">{error}</Typography>
        </Container>
      </ThemeProvider>
    );
  }

  if (!review) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ pt: 4 }}>
          <Typography>Review not found</Typography>
        </Container>
      </ThemeProvider>
    );
  }

  if (!authenticated) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="sm" sx={{ pt: 8 }}>
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
            <Typography variant="h5" component="h1" gutterBottom>
              {review.title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please enter your name and the password to access this review.
            </Typography>

            <TextField
              label="Your Name"
              fullWidth
              margin="normal"
              value={commenterName}
              onChange={(e) => setCommenterName(e.target.value)}
              variant="filled"
              required
              InputProps={{ disableUnderline: true }}
            />

            {review.password && (
              <TextField
                label="Password"
                type="password"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={Boolean(error)}
                helperText={error}
                variant="filled"
                required
                InputProps={{ disableUnderline: true }}
              />
            )}

            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleAuthenticate}
              disabled={!commenterName || (review.password && !password)}
            >
              Access Review
            </Button>
          </Paper>
        </Container>
      </ThemeProvider>
    );
  }

  const selectedVersion =
    selectedVersionIndex !== null
      ? review.versions[selectedVersionIndex]
      : null;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ pt: 2, pb: 4 }}>
        <Paper elevation={3} sx={{ p: 3, borderRadius: 2, mb: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5" component="h1">
              {review.title}
            </Typography>

            <FormControl variant="filled" sx={{ minWidth: 120 }}>
              <InputLabel>Version</InputLabel>
              <Select
                value={selectedVersionIndex}
                onChange={handleVersionChange}
                size="small"
                disableUnderline
              >
                {review.versions.map((version, index) => (
                  <MenuItem key={index} value={index}>
                    V{index + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {selectedVersion ? (
            <Box sx={{ width: "100%", mb: 2, position: "relative" }}>
              <div
                ref={videoContainerRef}
                style={{ position: "relative", width: "100%" }}
                onMouseMove={handleMouseMove}
                onClick={handlePlayPause}
              >
                <video
                  ref={videoRef}
                  src={selectedVersion.sharedLink}
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onTimeUpdate={handleTimeUpdate}
                  onLoadedMetadata={handleLoadedMetadata}
                  onEnded={handleVideoEnded}
                  playsInline
                  controls={false}
                />

                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: "10px",
                    background: "rgba(0, 0, 0, 0.7)",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                    transition: "opacity 0.3s ease",
                    opacity: showControls ? 1 : 0,
                    pointerEvents: showControls ? "auto" : "none",
                  }}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      togglePlay();
                    }}
                    size="small"
                    sx={{ color: "white" }}
                  >
                    {isPlaying ? <Pause /> : <PlayArrow />}
                  </IconButton>

                  <Typography
                    variant="caption"
                    sx={{ color: "white", minWidth: "50px" }}
                  >
                    {formatTime(videoTime)}
                  </Typography>

                  <div
                    ref={seekBarContainerRef}
                    style={{
                      position: "relative",
                      flex: 1,
                      height: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {renderCommentMarkers()}
                    <input
                      ref={seekBarRef}
                      type="range"
                      min="0"
                      max="100"
                      step="0.1"
                      defaultValue="0"
                      onChange={handleSeekBarChange}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <Typography
                    variant="caption"
                    sx={{ color: "white", minWidth: "50px" }}
                  >
                    {formatTime(duration)}
                  </Typography>

                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleMute();
                    }}
                    size="small"
                    sx={{ color: "white" }}
                  >
                    {isMuted ? <VolumeOff /> : <VolumeUp />}
                  </IconButton>

                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleFullscreen();
                    }}
                    size="small"
                    sx={{ color: "white" }}
                  >
                    <Fullscreen />
                  </IconButton>
                </div>
              </div>
            </Box>
          ) : (
            <Typography>No video available for this review.</Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography variant="h6">
              Comments ({selectedVersion?.comments?.length || 0})
            </Typography>
            {isLatestVersion && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<CommentIcon />}
                onClick={handleAddComment}
              >
                Add Comment
              </Button>
            )}
          </Box>

          <Divider sx={{ my: 2 }} />

          {selectedVersion?.comments?.length > 0 ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {selectedVersion.comments.map((comment, index) => (
                <Card
                  key={index}
                  variant="outlined"
                  sx={{
                    borderLeft: `4px solid ${comment.colour || "#ff5722"}`,
                    "&:hover": {
                      boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
                      cursor: "pointer",
                    },
                    opacity: comment.completed ? 0.7 : 1,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        {comment.commenter}
                      </Typography>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Checkbox
                          checked={comment.completed || false}
                          onChange={() =>
                            handleToggleCompleted(
                              index,
                              comment.completed || false
                            )
                          }
                          icon={<CheckCircle color="disabled" />}
                          checkedIcon={<CheckCircle color="success" />}
                          onClick={(e) => e.stopPropagation()}
                        />

                        {/* Delete button - only show for latest version */}
                        {isLatestVersion && (
                          <Tooltip title="Delete comment">
                            <IconButton
                              size="small"
                              color="error"
                              onClick={(e) => handleOpenDeleteConfirm(index, e)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Chip
                          icon={<AccessTimeIcon fontSize="small" />}
                          label={
                            comment.timeFormatted || formatTime(comment.time)
                          }
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSeekToComment(comment.time);
                          }}
                        />
                      </Box>
                    </Box>
                    <Typography
                      variant="body1"
                      onClick={() => handleSeekToComment(comment.time)}
                      sx={{
                        textDecoration: comment.completed
                          ? "line-through"
                          : "none",
                      }}
                    >
                      {comment.comment}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          ) : (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", py: 4, color: "text.secondary" }}
            >
              No comments yet. {isLatestVersion ? "Add the first one!" : ""}
            </Typography>
          )}
        </Paper>
      </Container>

      {/* Add Comment Modal */}
      <Modal
        open={commentModalOpen}
        onClose={() => {
          setCommentModalOpen(false);
          videoRef.current.play();
          videoRef.current.controls = false;
        }}
        disableAutoFocus
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" gutterBottom>
            Add Comment at {formatTime(currentTime)}
          </Typography>

          <TextField
            label="Comment"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            variant="filled"
            InputProps={{ disableUnderline: true }}
          />

          <FormControl fullWidth margin="normal" variant="filled">
            <InputLabel id="color-label">Comment Color</InputLabel>
            <Select
              labelId="color-label"
              value={commentColor}
              onChange={(e) => setCommentColor(e.target.value)}
              disableUnderline
            >
              <MenuItem value="#2196f3" sx={{ color: "#2196f3" }}>
                Blue
              </MenuItem>
              <MenuItem value="#4caf50" sx={{ color: "#4caf50" }}>
                Green
              </MenuItem>
              <MenuItem value="#9c27b0" sx={{ color: "#9c27b0" }}>
                Purple
              </MenuItem>
              <MenuItem value="#f44336" sx={{ color: "#f44336" }}>
                Red
              </MenuItem>
              <MenuItem value="#ff5722" sx={{ color: "#ff5722" }}>
                Orange
              </MenuItem>
              <MenuItem value="#ffeb3b" sx={{ color: "#ffeb3b" }}>
                Yellow
              </MenuItem>
            </Select>
          </FormControl>

          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 1 }}
          >
            <Button
              onClick={() => {
                setCommentModalOpen(false);
                videoRef.current.play();
                videoRef.current.controls = false;
              }}
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveComment}
              variant="contained"
              color="primary"
              disabled={!newComment.trim()}
            >
              Save Comment
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        open={deleteConfirmOpen}
        onClose={() => {
          setDeleteConfirmOpen(false);
          setCommentToDelete(null);
        }}
        disableAutoFocus
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" gutterBottom>
            Delete Comment
          </Typography>

          <Typography variant="body1" sx={{ mb: 3 }}>
            Are you sure you want to delete this comment? This action cannot be
            undone.
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button
              onClick={() => {
                setDeleteConfirmOpen(false);
                setCommentToDelete(null);
              }}
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteComment}
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default VideoReview;
