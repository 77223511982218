import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const DropboxError = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setErrorMessage(params.get('message') || 'Unknown error occurred');
    
    // Set a timeout to redirect to admin page
    // const timer = setTimeout(() => navigate('/admin'), 5000);
    // return () => clearTimeout(timer);
  }, [navigate, location]);

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h2>Dropbox Connection Error</h2>
      <p style={{ color: 'red' }}>{errorMessage}</p>
      <p>Redirecting back to admin dashboard...</p>
    </div>
  );
};

export default DropboxError;
