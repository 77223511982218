import React, { useState, useEffect, useRef } from "react";
import Isotope from "isotope-layout";
import "isotope-packery";

import imagesLoaded from "imagesloaded";
import { useLocation } from "react-router-dom";

import { createGlobalStyle } from "styled-components";

import { useInViewEffect } from "react-hook-inview";
import workContent from "./workContent";

const GlobalStyles = createGlobalStyle`
.grid {
 width: 100%;
 position: relative;
}

.grid-sizer,
.grid-item {
 width: 25%;
 display: flex;
 justify-content: center;
}

.grid-item-span-2 {
 width: 50%;
}

.grid-item-span-3 {
 width: 75%;
}

.grid-item-span-4 {
 width: 100%;
}

@media (max-width: 1200px) {
 .grid-sizer,
 .grid-item {
   width: 50%;
 }
 
 .grid-item-span-2,
 .grid-item-span-3,
 .grid-item-span-4 {
   width: 100%;
 }
}

@media (max-width: 768px) {
 .grid-sizer,
 .grid-item,
 .grid-item-span-2,
 .grid-item-span-3,
 .grid-item-span-4 {
   width: 100%;
 }
}

.grid-item {
 padding-left: 4px;
 padding-right: 4px;
 padding-bottom: 4px;
 margin-bottom: 4px;
 box-sizing: border-box;
}

.grid-item picture,
.grid-item img {
 display: block;
 width: 100%;
 height: auto;
}
`;

const Work = ({ logEvent }) => {
 const gridRef = useRef(null);
 const location = useLocation();
 const [triggerMasonryRender, setTriggerMasonryRender] = useState(false);
 const isotopeInstanceRef = useRef(null);
 const scrollPositionRef = useRef(0);

 useEffect(() => {
   logEvent({ event: "work_render" })
     .then((result) => console.log("Logged successfully:", result))
     .catch((error) => console.error("Error logging:", error));
 }, [logEvent]);

 useEffect(() => {
   scrollPositionRef.current = window.pageYOffset;
   
   isotopeInstanceRef.current = new Isotope(gridRef.current, {
     itemSelector: ".grid-item",
     layoutMode: "packery",
     percentPosition: true,
     packery: {
       columnWidth: ".grid-sizer",
       gutter: 0,
     },
     transitionDuration: "0.4s",
   });

   imagesLoaded(gridRef.current).on("progress", () => {
     isotopeInstanceRef.current.layout();
     window.scrollTo(0, scrollPositionRef.current);
   });

   const params = new URLSearchParams(location.search);
   const category = params.get("category");

   if (category) {
     let filterValue = "*";
     if (category === "photography") {
       filterValue = '[data-kind="photography"]';
     } else if (category === "videography") {
       filterValue = '[data-kind="videography"]';
     } else if (category === "videography-creative") {
       filterValue = '[data-kind="videography"][data-subkind="creative"]';
     } else if (category === "videography-ecomm") {
       filterValue = '[data-kind="videography"][data-subkind="ecomm"]';
     }
     isotopeInstanceRef.current.arrange({ filter: filterValue });
   } else {
     isotopeInstanceRef.current.arrange({ filter: "*" });
   }

   return () => isotopeInstanceRef.current.destroy();
 }, [location.search]);

 useEffect(() => {
   if (isotopeInstanceRef.current) {
     scrollPositionRef.current = window.pageYOffset;
     isotopeInstanceRef.current.layout();
     setTimeout(() => {
       window.scrollTo(0, scrollPositionRef.current);
     }, 10);
   }
 }, [triggerMasonryRender]);

 const handleVideoLoaded = () => {
   scrollPositionRef.current = window.pageYOffset;
   setTriggerMasonryRender((prev) => !prev);
 };

 return (
   <>
     <GlobalStyles />
     <div className="grid" ref={gridRef}>
       <div className="grid-sizer"></div>
       {workContent.map((item, index) => {
         if (item.type === "video") {
           return (
             <Video
               key={index}
               webmSrc={item.webmSrc}
               mp4Src={item.mp4Src}
               poster={item.poster}
               subKind={item.subKind}
               handleVideoLoaded={handleVideoLoaded}
               shouldAutoPlayOnLoad={item.shouldAutoPlayOnLoad}
               columnSpan={item.columnSpan}
             />
           );
         } else if (item.type === "photo") {
           return (
             <Photo
               key={index}
               jxlSrcSet={item.jxlSrcSet}
               jpgSrc={item.jpgSrc}
               jpgSrcSet={item.jpgSrcSet}
               loading={item.loading}
               kind={item.kind}
               sizes={item.sizes}
               columnSpan={item.columnSpan}
             />
           );
         }
         return null;
       })}
     </div>
   </>
 );
};

export default Work;

const Video = ({
 webmSrc,
 mp4Src,
 poster,
 subKind,
 handleVideoLoaded,
 shouldAutoPlayOnLoad = false,
 columnSpan = 1,
}) => {
 const [firstLoaded, setFirstLoaded] = useState(false);

 useEffect(() => {
   setTimeout(() => {
     setFirstLoaded(true);
   }, 2000);
 }, [setFirstLoaded]);

 const vidRef = useRef(null);
 const ref = useInViewEffect(
   ([entry]) => {
     if (entry.isIntersecting) {
       if (firstLoaded || shouldAutoPlayOnLoad) {
         vidRef.current.play();
       }
     } else {
       vidRef.current.pause();
     }
   },
   { threshold: 0.9 },
   [firstLoaded]
 );

 const spanClass = columnSpan > 1 ? `grid-item-span-${columnSpan}` : "";

 return (
   <div
     className={`grid-item ${spanClass}`}
     data-kind="videography"
     data-subkind={subKind}
     ref={ref}
   >
     <video
       ref={vidRef}
       poster={poster}
       loop={true}
       muted={true}
       playsInline={true}
       preload={shouldAutoPlayOnLoad ? "auto" : "metadata"}
       onLoadedData={handleVideoLoaded}
       style={{ width: "100%" }}
     >
       <source src={webmSrc} type="video/webm" />
       <source src={mp4Src} type="video/mp4" />
     </video>
   </div>
 );
};

const Photo = ({
 jxlSrcSet,
 jpgSrc,
 jpgSrcSet,
 loading,
 kind,
 sizes,
 columnSpan = 1,
}) => {
 const spanClass = columnSpan > 1 ? `grid-item-span-${columnSpan}` : "";

 return (
   <div className={`grid-item ${spanClass}`} data-kind={kind}>
     <picture loading={loading}>
       <source
         type="image/jxl"
         srcSet={jxlSrcSet}
         sizes="(max-width: 1000px) 100vw, 1000px"
         loading="lazy"
       />
       <img
         src={jpgSrc}
         srcSet={jpgSrcSet}
         sizes={sizes}
         alt="Description"
         loading={loading}
         style={{ width: "100%" }}
       />
     </picture>
   </div>
 );
};