import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DropboxSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Set a timeout to redirect to admin page
    const timer = setTimeout(() => navigate('/admin'), 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h2>Dropbox Connected Successfully!</h2>
      <p>Redirecting back to admin dashboard...</p>
    </div>
  );
};

export default DropboxSuccess;